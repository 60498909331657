import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap');

  * {
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
  }

  html, body, #root {
    min-height: 100vh;
    width: 100%;
  }

`;

export default GlobalStyle;