import React from "react";

import {
  Container,
  LeftSide,
  MainContent,
  Contact,
  RightSide,
  Copyright
} from "./styles";

import logo from "../../assets/building/logo.png";

export default function Building() {
  return (
    <Container>
      <LeftSide>
        <MainContent>
          <img src={logo} alt="logo agalab" />

          <h1>Estamos em construção</h1>

          <p>Em breve nosso conteúdo estará disponível.</p>
          <p>Para mais informações, entre em contato:</p>

          <Contact>
            <p>aga.inocat@gmail.com</p>
          </Contact>
        </MainContent>

        <Copyright>
          <p>AGALAB Laboratório Analítico© 2020. All rights reserved.</p>
        </Copyright>
      </LeftSide>
      <RightSide>
        <div />
      </RightSide>
    </Container>
  );
}
