import styled from "styled-components";

import background from "../../assets/building/background.jpg";

const Flexbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled(Flexbox)`
  height: 100vh;
  width: 100%;
`;

export const RightSide = styled(Flexbox)`
  width: 60%;
  height: 100%;
  background: url(${background});
  background-size: cover;

  @media screen and (max-width: 1200px) {
    width: 0%;
  }
`;

export const LeftSide = styled(Flexbox)`
  width: 40%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const MainContent = styled(Flexbox)`
  flex-direction: column;
  height: 100%;

  img {
    width: 200px;
    margin-bottom: 35px;
  }

  h1 {
    font-size: 32px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    color: #333;
  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const Underline = styled.div`
  height: 4px;
  width: 100px;
  background: rgb(0, 134, 210);
  background: linear-gradient(
    133deg,
    rgba(0, 134, 210, 1) 0%,
    rgba(0, 176, 81, 1) 100%
  );
  border-radius: 10px;
  margin-bottom: 30px;
`;

export const Contact = styled(Flexbox)`
  margin-top: 10px;
  font-size: 14px;
  transition: 0.2s;

  &:hover {
    color: #0289c8;
    cursor: pointer;

    img {
      background-color: red;
      color: #0289c8;
    }
  }

  img {
    color: #333;
    height: 20px;
    width: 20px;
    margin: 0;
    margin-right: 10px;
    padding: 0;

    &:hover {
      color: red;
    }
  }
`;

export const Copyright = styled(Flexbox)`
  p {
    font-size: 12px;
  }

  @media screen and (max-width: 330px) {
    p {
      font-size: 10px;
    }
  }
`;
